/* General Styles */
body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  line-height: 1.6;
  color: #333;
  background: linear-gradient(to bottom, #f9f9f9, #e3f2fd);
}

/* Header */
header {
  background: linear-gradient(135deg, #0073e6, #0056b3);
  color: #fff;
  text-align: center;
  padding: 2em 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

header h1 {
  margin: 0;
  font-size: 2.8em;
  letter-spacing: 2px;
}

header p {
  margin: 0.5em 0 0;
  font-size: 1.3em;
  font-style: italic;
}

/* Main Section */
main {
  padding: 2em 10%;
}

main section {
  margin-bottom: 2em;
  padding: 2em;
  background: #fff;
  border-radius: 10px;
  border-left: 6px solid #0073e6;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  animation: fadeIn 0.6s ease;
}

main section:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

main section h2 {
  margin-top: 0;
  color: #0056b3;
  font-size: 2em;
  border-bottom: 2px solid #e3f2fd;
  padding-bottom: 0.5em;
}

main section h3 {
  font-size: 1.5em;
  color: #0073e6;
  margin-top: 1.5em;
}

main section ul,
main section ol {
  padding-left: 1.5em;
  margin: 1em 0;
}

main section ul li,
main section ol li {
  margin-bottom: 1em;
  line-height: 1.6;
}

main section ul li strong,
main section ol li strong {
  color: #0073e6;
  font-weight: bold;
}

/* Buttons and Links */
button,
a {
  text-decoration: none;
  background: linear-gradient(90deg, #0073e6, #0056b3);
  color: #fff;
  padding: 0.8em 1.5em;
  border-radius: 5px;
  transition: background 0.3s ease;
}

button:hover,
a:hover {
  background: linear-gradient(90deg, #0056b3, #003c82);
}

/* Cards for Checkpoints */
.checkpoint-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
}

.checkpoint-card {
  flex: 1;
  min-width: 250px;
  max-width: 300px;
  padding: 20px;
  border-radius: 20px;
  color: #fff;
  text-align: left;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.checkpoint-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.checkpoint-card h3 {
  margin: 0 0 10px 0;
  font-size: 1.8em;
}

.checkpoint-card h4 {
  margin: 0 0 15px 0;
  font-size: 1.3em;
}

.card-a {
  background: linear-gradient(135deg, #ff4081, #ff80ab);
}

.card-b {
  background: linear-gradient(135deg, #4caf50, #81c784);
}

.card-c {
  background: linear-gradient(135deg, #ff9800, #ffb74d);
}

.card-d {
  background: linear-gradient(135deg, #2196f3, #64b5f6);
}

/* Responsive Design */
@media (max-width: 768px) {
  .checkpoint-container {
    flex-direction: column;
    align-items: center;
  }
}

/* Footer */
footer {
  text-align: center;
  padding: 1.5em 0;
  background: #333;
  color: #fff;
}

footer p {
  margin: 0;
  font-size: 0.9em;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.hidden {
  opacity: 0;
  transform: translateY(30px);
  transition: opacity 0.8s ease, transform 0.8s ease;
}

.visible {
  opacity: 1;
  transform: translateY(0);
}

/* Hero Section */
.hero {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #ff9800, #ff6f61);
  color: #fff;
  text-align: center;
  box-shadow: inset 0 0 100px rgba(0, 0, 0, 0.3);
}

.hero-content {
  max-width: 800px;
  padding: 20px;
  animation: fadeIn 1s ease;
}

.hero h1 {
  font-size: 4em;
  margin: 0 0 10px;
  letter-spacing: 3px;
}

.hero p {
  font-size: 1.5em;
  margin: 0 0 20px;
  font-style: italic;
  color: #e3f2fd;
}

.hero-sub {
  margin-top: 30px;
  padding: 10px 20px;
  border: 2px solid #e3f2fd;
  border-radius: 10px;
  display: inline-block;
}

.hero-sub h2 {
  font-size: 2em;
  margin: 0;
  color: #fff;
}

/* Ensure content below Hero is not cut off */
main {
  padding-top: 100px;
}

.info-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  padding: 20px;
}

.card {
  flex: 1 1 calc(25% - 40px); /* Adjust size dynamically for responsiveness */
  /* background: linear-gradient(135deg, #0073e6, #0056b3); */

  background: linear-gradient(
    135deg,
    #e3f2fd,
    #bbdefb
  ); /* Softer background gradient */
  color: #fff;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 20px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
  font-weight: bold;
}

.card p {
  font-size: 1em;
  line-height: 1.6;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

@media (max-width: 768px) {
  .card {
    flex: 1 1 100%;
  }
}

.card {
  flex: 1 1 calc(25% - 40px); /* Adjust size dynamically for responsiveness */
  background: linear-gradient(
    135deg,
    #e3f2fd,
    #bbdefb
  ); /* Softer background gradient */
  color: #333; /* Ensures text color contrasts well */
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 20px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
  font-weight: bold;
  color: #0073e6; /* Vibrant color for better contrast */
}

.card p {
  font-size: 1em;
  line-height: 1.6;
  color: #333; /* Matches card text color */
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.timeline-section {
  background: #f9f9f9;
  padding: 40px 20px;
}

.timeline {
  position: relative;
  margin: 20px auto;
  padding: 0;
  max-width: 800px;
  list-style: none;
}

.timeline-item {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  position: relative;
}

.timeline-item:last-child {
  margin-bottom: 0;
}

.timeline-icon {
  flex-shrink: 0;
  width: 50px;
  height: 50px;
  background: #0073e6;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  font-weight: bold;
  margin-right: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.timeline-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  border-left: 6px solid #0073e6;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex-grow: 1;
}

.timeline-content h3 {
  margin: 0 0 10px;
  font-size: 1.5em;
  color: #0056b3;
}

.timeline-content p {
  margin: 0;
  font-size: 1em;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .timeline-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .timeline-icon {
    margin-bottom: 10px;
  }
}
.tools-section {
  background: #f9f9f9;
  padding: 40px 20px;
}

.tools-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.tool-card {
  background: #fff;
  padding: 20px;
  border-radius: 15px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.tool-card img {
  width: 80px;
  height: auto;
  margin-bottom: 15px;
}

.tool-card h3 {
  font-size: 1.4em;
  color: #0056b3;
  margin-bottom: 10px;
}

.tool-card p {
  font-size: 1em;
  line-height: 1.6;
  color: #333;
}

.tool-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

@media (max-width: 768px) {
  .tools-grid {
    grid-template-columns: 1fr;
  }
}

/* || REFERENCES || */

.reference-section {
  padding: 0px var(--px128);
  margin: 0px 0px var(--px256) 0px;
  text-align: center;
}
/* Make the reference text aligned properly */
.reference-p {
  text-indent: -0.5in;
  margin-left: 0.5in;
  line-height: 1.5;
  word-wrap: break-word; /* Allows the text to wrap if necessary */
  white-space: normal; /* Prevents unnecessary breaks */
}

.reference-h1 {
  font-weight: bold;
  font-size: 24px;
  text-align: center;
}

.reference-div {
  margin-top: 20px;
  text-align: left;
}

/* Ensure links stay inline and inherit indentation */
.reference-a {
  display: inline; /* Ensure the link stays inline */
  text-decoration: none;
  word-wrap: break-word;
  color: lightblue;
}

/* Scroll Animation */
/* Scroll Indicator */
.scroll-indicator {
  position: absolute;
  bottom: 12%; /* Adjust for visibility */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 10;
}

.arrow-down {
  display: inline-block;
  width: 0; /* Arrow uses border-based design */
  height: 0;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-top: 40px solid #0073e6; /* Adjusted for double size */
  animation: bounce-scale 2s infinite, gradient-flash 3s infinite;
  filter: drop-shadow(0 0 10px rgba(0, 115, 230, 0.8)); /* Glow effect */
}
/* Bounce and Scale Animation */
@keyframes bounce-scale {
  0%,
  100% {
    transform: translateY(0) scale(1);
  }
  50% {
    transform: translateY(-10px) scale(1.2); /* Add scaling */
  }
}

/* Gradient Flash Animation */
@keyframes gradient-flash {
  0% {
    border-top-color: #0073e6;
    filter: drop-shadow(0 0 10px rgba(0, 115, 230, 0.8));
  }
  25% {
    border-top-color: #ff4081;
    filter: drop-shadow(0 0 10px rgba(255, 64, 129, 0.8));
  }
  50% {
    border-top-color: #4caf50;
    filter: drop-shadow(0 0 10px rgba(76, 175, 80, 0.8));
  }
  75% {
    border-top-color: #ff9800;
    filter: drop-shadow(0 0 10px rgba(255, 152, 0, 0.8));
  }
  100% {
    border-top-color: #0073e6;
    filter: drop-shadow(0 0 10px rgba(0, 115, 230, 0.8));
  }
}
